<template>
    <div class="datamodify">
        <Top name="基础资料修改" back email></Top>
        <div class="datamodify_w">
            <div class="photo_w">
                <img :src="uploadSrc" alt="">
                <van-uploader 
                :after-read="afterRead" 
                :max-size="10000 * 1024"
                @oversize="onOversize"
                ref="upload"
                >
                    <van-button icon="plus" type="primary">上传文件</van-button>
                </van-uploader>
            </div>
            <p>点击头像框可进行头像更换</p>
            <div class="inp">
                <label>用户名：</label>
                <input v-model="nickname" type="text" placeholder="玩家名字叫什么">
            </div>
            <van-button round type="info" size="large" color="linear-gradient(to right, #FF704D, #F73727)" @click="modifyConfirm">确认修改</van-button>
        </div>
    </div>
</template>
<script>
import base from '@/api/base'
import Top from '@/components/top.vue'

export default {
    name: 'Datamodify',
    components: {
        Top,
    },
    data() {
        return {
            uploadSrc: require('../../assets/images/profilePhoto.png'),
            imgFile:{},
            nickname:''
        }
    },
    created() {
        //获取基础资料
        this.getInfo();
    },
    methods: {
        onOversize(file) {
            this.$toast('文件大小不能超过 10Mb');
        },
        //确认修改
        modifyConfirm() {
            let formData=new FormData();//通过formdata上传
            if (this.imgFile.file) {
                formData.append('image',this.imgFile.file);
            }else{
                formData.append('image','');
            }
            formData.append('nickname',this.nickname);
            this.$api.account.updateBasicInformation(formData).then(res=>{
                if(res.code==200){
                    this.$toast({
                        message: '修改成功',
                        duration: 1500
                    });
                    // this.$router.push('/setting');
                    this.$router.back(-1);
                }
            })
        },
        //文件上传完毕后回调
        afterRead(file) {
            //console.log(file.file);
            this.uploadSrc = file.content;
            this.imgFile = file;
        },
        //获取默认头像
        getInfo() {
            this.$api.account.BasicInformation().then(res=>{
                if (res.code == 200) {
                    this.nickname = res.data.nickname;
                    if (res.data.headPortrait) {
                        this.uploadSrc = base.bd + '/' + res.data.headPortrait;
                    }else{
                        this.uploadSrc = require('../../assets/images/profilePhoto.png');
                    }
                }
            })
        }
    }
}
</script>
<style scoped>
.datamodify{
    min-height: 100%;
    background-color: #ececec;
}
.datamodify_w{
    font-size: .9375rem;
    color: #909090;
    background-color: #fff;
    padding: 1rem 0;
}
.datamodify_w p{
    margin: .625rem 0 1.4375rem;
}
.datamodify_w .inp{
    background-color: #efefef;
    border-radius: 5px;
    width: 90%;
    margin: 0 auto;
    padding: .5625rem;
}
.datamodify_w .inp label{
    color: #313131;
}
.datamodify_w .inp input{
    color: #313131;
    background: none;
    padding: 5px;
}
.datamodify_w button{
    width: 90%;
    margin-top: 50%;
    font-size: 1.2rem;
}
/* 上传文件样式 */
.datamodify_w .photo_w{
    position: relative;
    display: inline-block;
}
.datamodify_w img{
    width: 5.0625rem;
    height: 5.0625rem;
    border-radius: 50%;
    border: .125rem solid #ccc;
}
.datamodify_w .photo_w .van-uploader{
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
}
</style>
<style>
.datamodify_w .van-uploader .van-image img{
    width: 5.0625rem;
    height: 5.0625rem;
    border-radius: 50%;
    border: .125rem solid #ccc;
}
/* .datamodify_w .van-uploader .van-uploader__preview-delete{
    display: none;
} */
</style>